import React, { Component } from "react";
import "../style/customer.scss";
import HeadBar from "./common/HeadBar";
import ReactFlagsSelect from "react-flags-select";
import ReactPhoneInput from "react-telephone-input/lib/withStyles";
import flagImg from "../image/flag.png";
import * as FormValidation from "../utils/FormValidation";
import Checkbox from "@material-ui/core/Checkbox";
import * as FackbookAPI from "../apis/FacebookAPI";
import * as UpdateOrderAPI from "../apis/UpdateOrderAPI";
import Loading from "./common/Loading";
import SearchIcon from "../icon/SearchIcon";
import AllowRight from "../icon/AllowRight";
import UrlIcon from "../icon/UrlIcon";
import * as GetAddressAPI from "../apis/GetAddressAPI";
// import { Select } from "@material-ui/core";
import Select from "react-select";
import SolidAllowUp from "../icon/SolidAllowUp";
import SolidAllowDown from "../icon/SolidAllowDown";
import ShoppingCart from "../icon/ShoppingCart";
import ShippingCart from "../icon/ShippingCart";
import Approve from "../icon/Approve";
import AllowLeft from "../icon/AllowLeft";
import AllowsLeft from "../icon/AllowsLeft";

import T_shirt from "../image/T-shirt.webp";

import { BASE_URL } from "../constants/ApiEndpointConstants";
import { Radio } from "antd";
import Reload from "./common/Reload";
import * as SubmitPayAPI from "../apis/SubmitPayAPI";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "./common/Popup";
import Cookie from "react-cookies";
import * as GetPixelAPI from "../apis/GetPixelAPI";

import Payment from "./common/Payment";
import * as SetOrderAPI from "../apis/SetOrderAPI";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      country: "FR",
      firstName: "",
      lastName: "",
      email1: "",
      email2: "",
      phone1: "",
      phone2: "",
      address: "",
      province: null,
      state: "",
      SSN: "",
      zip: "",
      city: "",
      isEmail1: false,
      isEmail2: false,
      isPhone1: false,
      isPhone2: false,
      isFirstName: false,
      isLastName: false,
      isAddress: false,
      isProvince: false,
      isCountry: false,
      isZip: false,
      isSSN: false,
      isCity: false,
      tip: "",
      loadingShow: false,
      showCart: false,
      num: 0,
      total: 0,
      vehicleModel: "",
      vehicleModel2: "",
      vehicleModel3: "",
      reloadingShow: false,
      contactType: "T shirt",
      test_drive_package: 0,
      payment_intent_id: null,
      clientSecret: "",
      stripePromise: "",
      userDetails: {},
      count: 3,
      url: "",
      amount: "",
      title: "",
      img: [],
      iframeUrl: "",
    };
    this.inputChange = this.inputChange.bind(this);
    this.blurInput = this.blurInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.clickToCheck = this.clickToCheck.bind(this);
    this.routeDocument = this.routeDocument.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.showCart = this.showCart.bind(this);
    this.back = this.back.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
  }

  paymentRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("orderDetail")) {
      let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
      this.setState({
        num: orderDetail.num,
        total: orderDetail.amount,
        vehicleModel: orderDetail.vehicleModel,
        vehicleModel2: orderDetail.vehicleModel2,
        vehicleModel3: orderDetail.vehicleModel3,
        title: orderDetail.title,
        img: orderDetail.img,
      });
    }
    // --------------
    // iframe接收message！！！！！
    window.addEventListener("message", (event) => {
      // 检查事件的来源，以确保安全（可以根据需要指定event.origin）
      if (event.origin === "https://www.rateose.store") {
        const receivedData = event.data;
        console.log("Received data from project B:", receivedData);
        window.location.href = receivedData;
      }
    });
    // --------------
  }

  inputChange(e, type) {
    let telephone;
    switch (type) {
      case "email1":
        this.setState({
          email1: e.target.value,
          email2: e.target.value,
          tip: "",
        });
        break;
      case "email2":
        this.setState({ email2: e.target.value.trim(), tip: "" });
        break;
      case "firstName":
        this.setState({ firstName: e.target.value.trim(), tip: "" });
        break;
      case "lastName":
        this.setState({ lastName: e.target.value.trim(), tip: "" });
        break;
      case "country":
        this.setState({ country: e.target.value.trim(), tip: "" });
        break;
      case "city":
        this.setState({ city: e.target.value.trim(), tip: "" });
        break;
      case "address":
        this.setState({ address: e.target.value.trim(), tip: "" });
        break;
      case "zip":
        this.setState({ zip: e.target.value.trim(), tip: "" });
        break;
      case "province":
        this.setState({ province: e.target.value.trim(), tip: "" });
        break;
      case "SSN":
        this.setState({ SSN: e.target.value.trim(), tip: "" });
        break;
      case "phone1":
        telephone = e.replace("-", "").replace(" ", "");
        this.setState({ phone1: telephone, phone2: telephone, tip: "" });
        break;
      case "phone2":
        telephone = e.replace("-", "").replace(" ", "");
        this.setState({ phone2: telephone, tip: "" });
        break;
      default:
        break;
    }
  }

  blurInput(e, type) {
    let reg;
    switch (type) {
      case "email1":
        if (!FormValidation.isValidEmail(e.target.value)) {
          this.setState({ isEmail1: true, isEmail2: true });
        } else {
          this.setState({ isEmail1: false, isEmail2: false });
        }
        break;
      case "email2":
        if (!FormValidation.isValidEmail(e.target.value)) {
          this.setState({ isEmail2: true });
        } else {
          this.setState({ isEmail2: false });
        }
        break;
      case "firstName":
        if (e.target.value.trim() === "") {
          this.setState({ isFirstName: true });
        } else {
          this.setState({ isFirstName: false });
        }
        break;
      case "lastName":
        if (e.target.value.trim() === "") {
          this.setState({ isLastName: true });
        } else {
          this.setState({ isLastName: false });
        }
        break;
      case "country":
        if (e.target.value.trim() === "") {
          this.setState({ isCountry: true });
        } else {
          this.setState({ isCountry: false });
        }
        break;
      case "city":
        if (e.target.value.trim() === "") {
          this.setState({ isCity: true });
        } else {
          this.setState({ isCity: false });
        }
        break;
      case "address":
        if (e.target.value.trim() === "") {
          this.setState({ isAddress: true });
        } else {
          this.setState({ isAddress: false });
        }
        break;
      case "province":
        if (e.target.value.trim() === "") {
          this.setState({ isProvince: true });
        } else {
          this.setState({ isProvince: false });
        }
        break;
      case "SSN":
        reg = /^[0-9]{9}$/;
        if (!reg.test(e.target.value)) {
          this.setState({ isSSN: true });
        } else {
          this.setState({ isSSN: false });
        }
        break;
      case "zip":
        reg = /^[a-zA-Z0-9-_]{4,30}$/;
        if (!reg.test(e.target.value)) {
          this.setState({ isZip: true });
        } else {
          this.setState({ isZip: false });
        }
        break;
      case "phone1":
        if (FormValidation.isValidPhoneNumber(e)) {
          this.setState({ isPhone1: false, isPhone2: false });
        } else {
          this.setState({ isPhone1: true, isPhone2: true });
        }
        break;
      case "phone2":
        if (FormValidation.isValidPhoneNumber(e)) {
          this.setState({ isPhone2: false });
        } else {
          this.setState({ isPhone2: true });
        }
        break;
      default:
        break;
    }
  }

  handleChange(e) {
    this.setState({ checked: e.target.checked });
  }

  setSelected(code) {
    this.setState({ country: code });
    console.log(code);
  }

  // paypal设置订单!!!!!!!!!!!  -----start
  clickToCheck(type) {
    const {
      isAddress,
      isCountry,
      isEmail1,
      isEmail2,
      isFirstName,
      isLastName,
      isPhone1,
      isPhone2,
      isProvince,
      isZip,
      isSSN,
      isCity,
      firstName,
      lastName,
      email1,
      email2,
      province,
      address,
      zip,
      phone1,
      phone2,
      SSN,
      country,
      city,
      num,
      total,
      test_drive_package,
      c,
    } = this.state;

    let checkFlag =
      isAddress ||
      isCountry ||
      isEmail1 ||
      isEmail2 ||
      isFirstName ||
      isLastName ||
      isPhone1 ||
      isPhone2 ||
      isProvince ||
      isZip ||
      isCity ||
      (country === "US" && isSSN);
    if (checkFlag) {
      if (country === "US") {
        if (isSSN) {
          this.setState({ tip: "Invalid social security number" });
          return;
        }
      }
      if (isEmail1) {
        this.setState({ tip: "Invalid email address" });
        return;
      }
      if (isEmail2) {
        this.setState({ tip: "Invalid driver email address" });
        return;
      }
      if (isPhone1) {
        this.setState({ tip: "Invalid phone number" });
        return;
      }
      if (isPhone2) {
        this.setState({ tip: "Invalid driver phone number" });
        return;
      }
    } else {
      this.setState({ tip: "" });
      let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
      console.log(address);
      orderDetail.address = address;
      orderDetail.country = country;
      orderDetail.driveEmail = email2;
      orderDetail.drivePhone = phone2;
      orderDetail.email = email1;
      orderDetail.phone = phone1;
      orderDetail.firstName = firstName;
      orderDetail.lastName = lastName;
      orderDetail.snCode = SSN;
      orderDetail.city = city;
      orderDetail.state = province;
      orderDetail.zipCode = zip;

      let ad = "";
      if (localStorage.getItem("ad")) {
        ad = JSON.parse(localStorage.getItem("ad")) || "cutting";
      }
      orderDetail.ad = ad;
      orderDetail.id = "";
      orderDetail.fail_url = `${BASE_URL}/Fail?status=succeeded&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`;
      orderDetail.success_url = `${BASE_URL}/Success?status=succeeded&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`;
      // if (this.paymentRef) {
      //   orderDetail.cardBank = this.paymentRef.current.state.cardType;
      //   orderDetail.cardNumber = this.paymentRef.current.state.cardNumber;
      //   orderDetail.cardExpiry = this.paymentRef.current.state.expiry;
      //   orderDetail.cardCvc = this.paymentRef.current.state.cvc;
      // }

      // paypal设置订单
      let params = {
        data: JSON.stringify(orderDetail),
        type: type,
        url: BASE_URL.replace("https://", ""),
        orderNu: this.generateUniqueOrderNumber(),
        updatetime: new Date().getTime(),
      };

      SetOrderAPI.setOrder(params).then((res) => {
        console.log(res);
        console.log("新增订单！！！！！！！！！！！");
        var msg = res.data.msg;
        if (msg === "success") {
          var url = res.data.data.location;
          // window.location.href = url;;
          // 自动滑到底部
          setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
          }, 100);
          this.setState({ iframeUrl: url }); //设置iframe src
        }
      });

      // UpdateOrderAPI.updateOrderInformation(orderDetail)
      //   .then((res) => {
      //     this.toggleShow();
      //     var data = res.data.data;
      //     if (data) {
      //       console.log(data);
      //       orderDetail.id = data.id;
      //       console.log(orderDetail.id);
      //       localStorage.setItem("orderDetail", JSON.stringify(orderDetail));

      //       // this.clickToPay();
      //     }
      //   })
      //   .catch((err) => {});

      // // fbq;
      // if (window.fbq) {
      //   let event_id = Math.round(Math.random() * 10000000000);
      //   window.fbq(
      //     "track",
      //     "AddPaymentInfo",
      //     {
      //       content_name: orderDetail.vehicleModel,
      //       content_ids: [orderDetail.vehicleModel],
      //       currency: "EUR",
      //       value: orderDetail.amount,
      //       contents: [
      //         {
      //           id: orderDetail.vehicleModel,
      //           title: orderDetail.vehicleModel,
      //           quantity: 1,
      //         },
      //       ],
      //     },
      //     { eventID: event_id }
      //   );
      //   let customdata = {
      //     content_name: orderDetail.vehicleModel,
      //     content_ids: [orderDetail.vehicleModel],
      //     currency: "EUR",
      //     value: orderDetail.amount,
      //     contents: [
      //       {
      //         id: orderDetail.vehicleModel,
      //         title: orderDetail.vehicleModel,
      //         quantity: 1,
      //       },
      //     ],
      //   };
      //   let userdata = {
      //     em: email1,
      //     ph: phone1,
      //     fn: firstName,
      //     ln: lastName,
      //     ct: city,
      //     st: province,
      //     zp: zip,
      //     country: country,
      //     external_id: "",
      //     client_ip_address: "",
      //     client_user_agent: "",
      //     fbc: "",
      //     fbp: "",
      //     lead_id: "",
      //     anon_id: "",
      //     madid: "",
      //     page_id: "",
      //     page_scoped_user_id: "",
      //     ctwa_clid: "",
      //     ig_account_id: "",
      //     ig_sid: "",
      //   };
      //   FackbookAPI.Facebook(
      //     event_id,
      //     "AddPaymentInfo",
      //     customdata,
      //     userdata
      //   ).then((res) => {
      //     console.log(res);
      //   });
      // }
    }
  }
  // paypal设置订单!!!!!!!!!!!  -----end

  // 生成随机订单号----
  generateUniqueOrderNumber() {
    const timestamp = Date.now(); // 当前时间戳
    const randomNum = Math.floor(Math.random() * 1000000); // 生成一个 0 到 999999 的随机数
    return `${timestamp}-${randomNum}`; // 结合时间戳和随机数
  }

  // 原stripe支付
  clickToPay() {
    let { payment_intent_id, count, test_drive_package } = this.state;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    orderDetail.test_drive_package = test_drive_package;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    console.log(count);
    if (count === 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "Les achats d'aujourd'hui ont été épuisés!",
        })
      );
      // 24小时内只允许点三次
      let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      let value = "Prohibitd purchase";
      Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
      return;
    }
    count--;
    console.log(count);
    this.setState({ count });
    let ad = "";
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "cutting";
      console.log(ad);
    }
    orderDetail.ad = ad;
    orderDetail.success_url = "/Success";
    orderDetail.cancel_url = "/Fail";
    orderDetail.payment_intent_id = payment_intent_id;
    orderDetail.type = 10;
    orderDetail.curcrency = 1;
    this.toggleReload();
    SubmitPayAPI.submit(orderDetail)
      .then((res) => {
        console.log(res);
        this.toggleReload();
        var data = res.data.data.data;
        console.log(data);
        if (data !== null) {
          let stripePromise = loadStripe(data.api_publishable_key);
          // 自动滑到底部
          setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
          }, 2000);
          let userDetails = {
            name: orderDetail.firstName,
            email: orderDetail.email,
            address: {
              city: orderDetail.city,
              state: orderDetail.state,
              country: orderDetail.country,
              postal_code: orderDetail.zipCode,
              line1: orderDetail.address,
            },
          };
          this.setState({
            stripePromise,
            payment_intent_id: data.payment_intent_id,
            clientSecret: data.clientSecret,
            userDetails,
            url: `${BASE_URL}/success?payment_intent_id=${data.payment_intent_id}&subscriptionId=${data.subscriptionId}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
            // url: `http://localhost:3001/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
          });

          orderDetail.payment_intent_id = data.payment_intent_id;
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        } else {
          this.clickToPay();
        }
      })
      .catch((err) => {
        this.toggleReload();
      });

    if (window.fbq) {
      let event_id = Math.round(Math.random() * 10000000000);
      window.fbq(
        "track",
        "InitiateCheckout",
        {
          content_category: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "EUR",
          value: orderDetail.amount,
          num_items: 1,
          contents: [
            {
              id: orderDetail.vehicleModel,
              title: orderDetail.vehicleModel,
              quantity: 1,
            },
          ],
        },
        { eventID: event_id }
      );
      let customdata = {
        content_category: orderDetail.vehicleModel,
        content_ids: [orderDetail.vehicleModel],
        currency: "EUR",
        value: orderDetail.amount,
        num_items: 1,
        contents: [
          {
            id: orderDetail.vehicleModel,
            title: orderDetail.vehicleModel,
            quantity: 1,
          },
        ],
      };
      let userdata = {
        em: orderDetail.email,
        ph: orderDetail.phone,
        fn: orderDetail.firstName,
        ln: orderDetail.lastName,
        ct: orderDetail.city,
        st: orderDetail.state,
        zp: orderDetail.zipCode,
        country: orderDetail.country,
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      FackbookAPI.Facebook(
        event_id,
        "InitiateCheckout",
        customdata,
        userdata
      ).then((res) => {
        console.log(res);
      });
    }
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  routeDocument() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Order",
        search: location.search,
      });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  showCart() {
    this.setState({ showCart: !this.state.showCart });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      checked,
      country,
      isAddress,
      isCountry,
      isEmail1,
      isEmail2,
      isFirstName,
      isLastName,
      isPhone1,
      isPhone2,
      isProvince,
      isZip,
      email1,
      phone1,
      address,
      firstName,
      lastName,
      province,
      zip,
      SSN,
      isSSN,
      email2,
      phone2,
      city,
      isCity,
      tip,
      riBen,
      showCart,
      num,
      total,
      vehicleModel,
      vehicleModel2,
      vehicleModel3,
      clientSecret,
      stripePromise,
      userDetails,
      url,
      amount,
      title,
      img,
      iframeUrl,
    } = this.state;

    let next = false;

    let nextButton = false;

    nextButton =
      email1.trim() === "" ||
      email2.trim() === "" ||
      email1.trim() === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      phone1.trim() === "" ||
      phone2.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      city.trim() === "" ||
      (country === "US" ? SSN.trim() === "" : false);

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret,
      appearance,
      locale: "fr",
    };

    return (
      <React.Fragment>
        <Reload {...this.props} reloadingShow={this.state.reloadingShow} />
        <div className="customer-page">
          <div className="logo-head">DÉSTOCKAGE + LIVRAISON GRATUITE 🇫🇷</div>
          <div className="shipping-cart" onClick={this.showCart}>
            <div className="left">
              <ShoppingCart size={25} color="rgb(34, 119, 200)" />
              <p> {showCart ? "Cacher" : "Afficher"} résumé de la commande</p>
              {showCart ? (
                <SolidAllowUp size={15} color="rgb(34, 119, 200)" />
              ) : (
                <SolidAllowDown size={15} color="rgb(34, 119, 200)" />
              )}
            </div>
            <div className="right">€{total}</div>
          </div>
          <div
            className={`shoppingCart-content ${showCart ? "show-cart" : ""}`}
          >
            <div className="product-row">
              <div className="left">
                <div className="img-box">
                  <img src={img[0]} alt="" />
                  <div className="num">{num}</div>
                </div>
                <div className="title">
                  <div className="name">{title}</div>
                  {/* <div className="sku">L'offre groupée : {vehicleModel}</div>
                  <div className="sku">Couleur : {vehicleModel2}</div>
                  <div className="sku">Taille : {vehicleModel3}</div> */}
                </div>
              </div>
              <div className="price">€{total}</div>
            </div>
            <div className="subtotal-row">
              <div className="top-row">
                <div className="title">Sous-total :</div>
                <div className="price">€{total}</div>
              </div>
              <div className="bottom-row">
                <div className="title">
                  <ShippingCart size={24} color="#888888" /> Expédition :
                </div>
                <div className="price">Calculer plus tard</div>
              </div>
            </div>
            <div className="total-row">
              <div className="title">Total:</div>
              <div className="price">
                <span>EUR</span>€{total}
              </div>
            </div>
          </div>
          <div className="customer-box">
            <div className="title">Informations sur le contact</div>
            <div className="info-box">
              <div className="input-row">
                {/* email */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">e-mail</div>
                    <input
                      type="text"
                      value={email1 || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "email1");
                      }}
                      onBlur={(e) => this.blurInput(e, "email1")}
                    />
                  </div>
                </div>
              </div>
              <div className="title">Adresse de livraison</div>
              <div className="little-tip">
                <Approve size={14} color="#2AB419" />
                <p>
                  Votre adresse ne sera utilisée que pour la livraison de la
                  commande.
                </p>
              </div>
              <div className="input-row">
                <div className="half">
                  <div className="row">
                    <div className="tip-title">nations</div>
                    <ReactFlagsSelect
                      selected={this.state.country}
                      onSelect={(code) => this.setSelected(code)}
                    />
                  </div>
                </div>
              </div>
              <div className="input-row">
                <div className="half">
                  {/* firstname */}
                  <div className="row">
                    <div className="tip-title">prénom</div>
                    <input
                      type="text"
                      value={firstName || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "firstName");
                      }}
                      onBlur={(e) => this.blurInput(e, "firstName")}
                    />
                  </div>
                </div>
                <div className="half">
                  {/* lastname */}
                  <div className="row">
                    <div className="tip-title">nom de famille</div>
                    <input
                      type="text"
                      value={lastName || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "lastName");
                      }}
                      onBlur={(e) => this.blurInput(e, "lastName")}
                    />
                  </div>
                </div>
              </div>
              <div className="input-row">
                {/* zip */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">code postal</div>
                    <input
                      type="text"
                      value={zip || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "zip");
                      }}
                      onBlur={(e) => this.blurInput(e, "zip")}
                    />
                  </div>
                </div>
                {/* state */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">province</div>
                    <input
                      type="text"
                      value={province || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "province");
                      }}
                      onBlur={(e) => this.blurInput(e, "province")}
                    />
                  </div>
                </div>
              </div>
              {/* address */}
              <div className="input-row">
                <div className="half">
                  <div className="row">
                    <div className="tip-title">adresse</div>
                    <input
                      type="text"
                      value={address || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "address");
                      }}
                      onBlur={(e) => this.blurInput(e, "address")}
                    />
                  </div>
                </div>
                {/* city */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">city</div>
                    <input
                      type="text"
                      value={city || ""}
                      className="input"
                      onChange={(e) => {
                        this.inputChange(e, "city");
                      }}
                      onBlur={(e) => this.blurInput(e, "city")}
                    />
                  </div>
                </div>
              </div>
              <div className="input-row">
                {/* phone */}
                <div className="half">
                  <div className="row">
                    <div className="tip-title">téléphone</div>
                    <ReactPhoneInput
                      defaultCountry="fr"
                      value={phone1 || ""}
                      flagsImagePath={flagImg}
                      onChange={(e) => this.inputChange(e, "phone1")}
                      onBlur={(e) => this.blurInput(e, "phone1")}
                    />
                  </div>
                </div>
              </div>
              <div className="next-model">
                <div className={`permission ${next ? "" : "show-permission"}`}>
                  <div className="permission-row">
                    <Checkbox
                      checked={checked}
                      onChange={(e) => this.handleChange(e)}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    Conservez ces informations pour la prochaine fois.
                  </div>
                </div>
              </div>
              <div className="input-row">
                <div className="tip">{tip}</div>
              </div>
              {/* 银行卡号表单 */}
              {/* <Payment {...this.props} ref={this.paymentRef} /> */}
              {/* {nextButton ? (
                <div className="btn">Cliquer pour payer</div>
              ) : clientSecret !== "" ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm userDetails={userDetails} url={url} />
                </Elements>
              ) : (
                <div className="check" onClick={this.clickToCheck}>
                  Cliquer pour payer
                </div>
              )} */}
              {/* 银行卡提交按钮 */}
              {/* {nextButton ? (
                <div className="btn">Cliquer pour payer</div>
              ) : (
                <div
                  className="check"
                  onClick={() => this.clickToCheck("card")}
                >
                  Cliquer pour payer
                </div>
              )} */}

              {/* iframe--------- */}
              {iframeUrl !== "" ? (
                <iframe
                  src={iframeUrl}
                  title="Dynamic Iframe"
                  width="100% "
                  height="500px"
                  frameBorder="0"
                ></iframe>
              ) : (
                <div
                  className="check"
                  onClick={() => this.clickToCheck("paypal")}
                >
                  Cliquez sur le bouton Paypal
                </div>
              )}

              <div className="return-row">
                <div className="div" onClick={this.back}>
                  <AllowsLeft size={13} color="rgb(34, 119, 200)" />
                  <p>Return</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Customer;
